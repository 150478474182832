import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { SRLWrapper } from "simple-react-lightbox";

const Thumbnail = ({
  thumbnail,
  hiRes,
  name,
  thumbnailClassName,
  descClassName,
}) => {
  const options = {
    buttons: {
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: false,
      showPrevButton: false,
      showThumbnailsButton: false,
    },
    thumbnails: {
      showThumbnails: false,
    },
    settings: {
      disableWheelControls: true,
    },
    caption: {
      captionTextTransform: "uppercase",
    },
  };

  return (
    <div className="relative flex flex-col items-center justify-center">
      <SRLWrapper options={options}>
        <a href={hiRes}>
          <GatsbyImage
            image={thumbnail}
            alt={name}
            className={thumbnailClassName}
          />
        </a>
      </SRLWrapper>
      <div
        className={`${
          descClassName
            ? descClassName
            : "w-full bg-transparent xl:bg-gray5 py-2 px-2 text-xs md:text-base font-medium relative -m-2"
        }`}
      >
        {name}
      </div>
      {name === "043-Pearl-White" || name === "174-Black" ? (
        <p className="absolute w-full -bottom-7 text-2xs md:text-base ">
          <i className="text-actionBlue">*only available in Rain</i>
        </p>
      ) : null}
    </div>
  );
};

export default Thumbnail;
