// This will hold the context provider
import { Link } from "gatsby";
import React from "react";
import ContentContainer from "../container/ContentContainer";

const TitleDiv = ({ parentText, title, className, path }) => {
  return (
    <ContentContainer
      containerClassName={`bg-gray2 py-5 div-flex ${className}`}
      className="flex flex-col md:flex-row items-center justify-center md:justify-start"
    >
      <h1 className="text-2xl sm:text-4xl mr-2 font-raleway pr-2">
        <Link className="button-action" to="/">
          <strong>NWP</strong>
        </Link>
        {` `}
        {parentText ? (
          <Link className="button-action" to={"../".repeat(path?.length)}>
            {parentText.toUpperCase()}
          </Link>
        ) : (
          title.toUpperCase()
        )}
      </h1>
      {title !== undefined && parentText !== undefined ? (
        <>
          {path?.map((text, index) => {
            const parent = "../";
            return text === title ? (
              <div key={`titleDive${index}`} className="flex flex-col md:flex-row gap-2">
                <span className="hidden md:flex items-center justify-center">{`>`}</span>
                <span
                  key={`path${index}`}
                  className="text-base sm:text-xl text-gray3 pr-2"
                >{`${title}`}</span>
              </div>
            ) : (
              <div key={`titleDive${index}`} className="flex flex-col md:flex-row gap-2">
                <span className="hidden md:flex items-center justify-center">{`>`}</span>
                <Link
                  key={`path${index}`}
                  className="button-action"
                  to={parent.repeat(index + 1)}
                >
                  <span className="text-base sm:text-xl text-gray3 pr-2">{`${text}`}</span>
                </Link>
              </div>
            );
          })}
        </>
      ) : null}
    </ContentContainer>
  );
};

export default TitleDiv;
